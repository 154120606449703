/* eslint-disable prettier/prettier */
// src/hooks/useUpdateViaIframe.js

import { useEffect } from "react";

/**
 * Custom hook to handle iframe messages and update localStorage.
 * @param {string} expectedOrigin - The origin to validate incoming messages.
 * @param {string} keyName - The key this hook is responsible for updating in localStorage.
 */
export const useUpdateViaIframe = (expectedOrigin, keyName) => {
  useEffect(() => {
    const handleMessage = (event) => {
      // Validate the origin of the incoming message
      if (event.origin !== expectedOrigin) {
        console.warn(`Ignored message from unexpected origin: ${event.origin}`);
        return;
      }

      // Ensure the message contains the required key and value
      if (event.data && event.data.field === keyName) {
        const value = event.data.value;
        localStorage.setItem(keyName, value.toString());
        console.log(`LocalStorage updated: ${keyName} = ${value}`);
      } else {
        console.warn(
          `Ignored message for unrelated field: ${event.data?.field}`
        );
      }
    };

    // Add the event listener
    window.addEventListener("message", handleMessage);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [expectedOrigin, keyName]);
};
