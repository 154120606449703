/* eslint-disable prettier/prettier */
// src/pages/dashboard-page.js
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";
import Modal from "../components/modal";
import GoogleSSO from "../components/GoogleSSO";
import useIframeModal from "../hooks/useIframeModal";
import useIframeRedirect from "../hooks/useIframeRedirect";
import { useUpdateViaIframe } from "../hooks/useUpdateViaIframe";
import useLocalStorage from "../hooks/useLocalStorage";
import { fetchAccountData } from "../services/fetchAccountData";
import LoadingScreen from "../components/LoadingScreen";

const DashboardPage = () => {
  useDocumentTitle("Dashboard");

  const { user, isAuthenticated, isLoading } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [gmb_connected, setgmb_connected] = useState(null);
  const [paid, setPaid] = useState(null);
  const [cid] = useLocalStorage("cid", null); // cid is independent and retrieved from localStorage only
  const [userEmail, setUserEmail] = useLocalStorage("user_email", "");

  useIframeModal("https://tryrepbot.retool.com", () => setIsModalOpen(true));
  useIframeRedirect("https://tryrepbot.retool.com");
  useUpdateViaIframe("https://tryrepbot.retool.com", "paid");

  useEffect(() => {
    if (!isLoading) {
      const loadData = async () => {
        if (userEmail.length < 1) {
          console.log(
            "taskid: z99 - Checking localStorage for user_email value"
          );
          try {
            console.log("taskid: z99 - Fetching account data");
            const data = await fetchAccountData(user?.email);

            if (!data) {
              console.log("taskid: z99 - No account data found");
              setErrorMessage("No Account Found");
              return;
            }

            console.log("taskid: z99 - Saving account data to localStorage");
            Object.entries(data).forEach(([key, value]) => {
              if (key) localStorage.setItem(key, JSON.stringify(value));
            });

            setUserEmail(data.user_email);
          } catch (error) {
            console.error("taskid: z99 - Error loading account data", error);
            setErrorMessage("Failed to load account data.");
          }
        } else {
          const storedgmb_connected = localStorage.getItem("gmb_connected");
          const storedPaid = localStorage.getItem("paid");

          console.log(
            "taskid: z99 - Retrieved gmb_connected:",
            storedgmb_connected
          );
          console.log("taskid: z99 - Retrieved paid:", storedPaid);

          setgmb_connected(storedgmb_connected === "true");
          setPaid(storedPaid === "true");
        }
      };

      loadData();
    }
  }, [isLoading, userEmail, user, setUserEmail]);

  let iframeUrl = "https://tryrepbot.retool.com/p/dashboard-free"; // Default to free dashboard

  if (paid) {
    iframeUrl = gmb_connected
      ? "https://tryrepbot.retool.com/p/dashboard-paid-gmb-connected"
      : "https://tryrepbot.retool.com/p/dashboard-paid-manual";
  }

  const retoolData = {
    userEmail: user?.email || "",
    cid: cid || null,
  };

  const closeModal = () => setIsModalOpen(false);

  if (isLoading) return <LoadingScreen message="Loading Dashboard..." />;

  if (errorMessage) return <p>{errorMessage}</p>;

  return (
    <PageLayout>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <GoogleSSO />
        </Modal>
      )}
      {!isLoading && isAuthenticated && userEmail.length > 0 ? (
        <Retool
          url={iframeUrl}
          width="100%"
          height="800px"
          data={retoolData}
          onData={(data) =>
            console.log("taskid: z99 - Event received from Retool app", data)
          }
        />
      ) : (
        <p>Loading dashboard...</p>
      )}
    </PageLayout>
  );
};

export default DashboardPage;
