/* eslint-disable prettier/prettier */
// src/components/modal.js
import React from "react";
import "./modal.css"; // Assuming you have styles for the modal

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal-overlay">
      <div className="modal active">
        <div className="modal-content">
          {children} {/* Render children, which will be the GoogleSSONew component */}
        </div>
        <div className="modal-buttons">
          <button
            type="button"
            className="modal-cancel-button"
            onClick={() => onClose(null)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
