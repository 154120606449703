/* eslint-disable prettier/prettier */
// src/components/buttons/logout-button.js

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useCleanupLocalStorage } from "../../hooks/useCleanupLocalStorage";

export const LogoutButton = () => {
  const { logout } = useAuth0();
  const cleanupLocalStorage = useCleanupLocalStorage();

  const handleLogout = () => {
    cleanupLocalStorage(); // Clear all localStorage
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <button className="button__logout" onClick={handleLogout}>
      Log Out
    </button>
  );
};
