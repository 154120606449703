/* eslint-disable prettier/prettier */
// src/components/LoadingScreen.js

import React from "react";

const LoadingScreen = ({ message }) => (
  <div className="loading-screen">
    <p>{message || "Loading..."}</p>
  </div>
);

export default LoadingScreen;
