/* eslint-disable prettier/prettier */

// src/hooks/useFetchLocations.js
import { useState, useEffect } from "react";
import axios from "axios";
import useGMBIntegration from "./useGMBIntegration";

const useFetchLocations = () => {
  const [locations, setLocations] = useState([]);
  const [authData, setAuthData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { code } = useGMBIntegration(); // Get the code from GMB Integration

  useEffect(() => {
    if (code) {
      setLoading(true);
      (async () => {
        try {
          const response = await axios.post("/api/save-token", { code });
          const { locations: fetchedLocations, ...restData } = response.data;

          setLocations(fetchedLocations || []);
          setAuthData(restData);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [code]);

  return { locations, authData, loading, error };
};

export default useFetchLocations;
