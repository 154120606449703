/* eslint-disable prettier/prettier */
// src/components/SettingsTabs.js
import React, { useState, useEffect } from "react";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { styled } from "@mui/material/styles";
import { Tabs, Tab, Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import BillingComponent from "./BillingComponent";

const GlassBox = styled(Box)(({ theme }) => ({
  background: "rgba( 255, 255, 255, 0.25 )",
  boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
  backdropFilter: "blur( 8.0px )",
  borderRadius: "10px",
  padding: theme.spacing(3),
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: "1.5rem",
  paddingLeft: 20,
  paddingRight: 20,
}));

const SettingsTabs = () => {
  useDocumentTitle("Settings");
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState(
    parseInt(searchParams.get("tab"), 10) || 0
  );

  useEffect(() => {
    // This effect sets the tab based on the URL's query parameter
    const tabParam = searchParams.get("tab");
    if (tabParam !== null) {
      setTabValue(parseInt(tabParam, 10));
    }
  }, [searchParams]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    // Update the URL with the new tab index
    setSearchParams({ tab: newValue });
  };

  return (
    <GlassBox>
      <Tabs value={tabValue} onChange={handleChange} centered>
        <StyledTab label="Billing" />
      </Tabs>
      <Box pt={2}>{tabValue === 0 && <BillingComponent />}</Box>
    </GlassBox>
  );
};

export default SettingsTabs;
