/* eslint-disable prettier/prettier */
// src/hooks/useQueryParams.js
import { useEffect } from 'react';

// Helper function to sanitize input values
const sanitizeInput = (value) => {
  const element = document.createElement('div');
  element.innerText = value;
  return element.innerHTML;
};

export const useQueryParamsToLocalStorage = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    // Check if either "utm_type" or "utm_affiliate" is present
    if (urlParams.has('utm_type') || urlParams.has('utm_affiliate')) {
      // Define a whitelist of UTM parameters to store
      const utmKeys = ['utm_type', 'utm_affiliate'];

      utmKeys.forEach((utmKey) => {
        const value = urlParams.get(utmKey);
        if (value) {
          // Sanitize the value to prevent potential XSS or other malicious content
          const sanitizedValue = sanitizeInput(value);

          // Only save sanitized key-value pairs in localStorage
          localStorage.setItem(utmKey, sanitizedValue);
        }
      });
    }

  }, []); // Empty dependency array to run once on component mount
};
