/* eslint-disable prettier/prettier */
import React from "react";
import { Auth0Feature } from "./auth0-feature";

export const Auth0Features = () => {
  const featuresList = [
    {
      title: "Maximize Your Reputation with Automated Review Tracking",
      description:
        "Effortlessly track over 100+ review sites that matter to your business and industry with our platform's 24/7 automated tracking.",
      resourceUrl: "https://repbot.ai/features/",
      icon: "https://res.cloudinary.com/sm-gcs/image/upload/v1689154655/RepBot/review_tracking_fnraoi.svg",
    },
    {
      title: "Scale Your Business with Automation ",
      description:
        "Automated review response generation and publishing allows you to provide excellent customer service, even with limited resources. The responses can be customized to match your brand's unique voice and preferred tone, ensuring consistency.",
      resourceUrl: "https://repbot.ai/features/",
      icon: "https://res.cloudinary.com/sm-gcs/image/upload/v1689154655/RepBot/review_response_automation_gdmdpy.svg",
    },
    {
      title: "Protect Your Brand Reputation Effortlessly",
      description:
        "Our system proactively identifies and flags reviews that violate platform policies. By swiftly requesting the review removal, RepBot helps you maintain a favorable digital presence that reflects your brand's true value.",
      resourceUrl: "https://repbot.ai/features/",
      icon: "https://res.cloudinary.com/sm-gcs/image/upload/v1689154655/RepBot/protect_brand_aduwzx.svg",
    },
    {
      title: "Unlock Customer Insights with the power of AI",
      description:
        "RepBot uses a combination of 3 OpenAI models to provide on-demand detailed reporting. Gain insights on customer sentiment and quickly spot recurring themes and issues.",
      resourceUrl: "https://repbot.ai/features/",
      icon: "https://res.cloudinary.com/sm-gcs/image/upload/v1689154655/RepBot/ai_kbynua.svg",
    },
  ];

  return (
    <div className="auth0-features">
      <h2 className="auth0-features__title">Explore RepBot.ai Features</h2>
      <div className="auth0-features__grid">
        {featuresList.map((feature) => (
          <Auth0Feature
            key={feature.resourceUrl}
            title={feature.title}
            description={feature.description}
            resourceUrl={feature.resourceUrl}
            icon={feature.icon}
          />
        ))}
      </div>
    </div>
  );
};
