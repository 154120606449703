/* eslint-disable prettier/prettier */
// src/pages/brand-rules.js
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";
import useIframeRedirect from "../hooks/useIframeRedirect";
import { useUpdateViaIframe } from "../hooks/useUpdateViaIframe";

const BrandRulesPage = () => {
  useDocumentTitle("Brand Rules");

  const { user } = useAuth0();
  const [isPaid, setIsPaid] = useState(false);

  // Initialize iframe redirect
  useIframeRedirect("https://tryrepbot.retool.com");

  // Listen for iframe messages with "tone_saved" as the key
  useUpdateViaIframe("https://tryrepbot.retool.com", "tone_saved");

  useEffect(() => {
    const storedPaid = localStorage.getItem("paid");
    setIsPaid(storedPaid === "true");
  }, []);

  const retoolData = {
    userName: user.name,
    userEmail: user.email,
  };

  return (
    <PageLayout>
      {isPaid ? (
        <Retool
          url="https://tryrepbot.retool.com/p/brand-rules"
          width="100%"
          height="1000px"
          data={retoolData}
        />
      ) : (
        <div
          style={{ textAlign: "center", marginTop: "50px", minHeight: "70vh" }}
        >
          <h1>Paid Feature</h1>
          <h2>Please upgrade your account to enable this feature</h2>
        </div>
      )}
    </PageLayout>
  );
};

export default BrandRulesPage;
