/* eslint-disable prettier/prettier */
// src/pages/Post.js
import React, { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { PageLayout } from "../components/page-layout";
import { useAuth0 } from "@auth0/auth0-react";

const Post = () => {
  const { isAuthenticated } = useAuth0();
  const [alertMessage, setAlertMessage] = useState("");
  const location = useLocation();

  useEffect(() => {
    const checkoutId = new URLSearchParams(location.search).get("c_id");

    if (!checkoutId) {
      window.location.href = "https://repbot.ai";
      return;
    }

    localStorage.setItem("cid", checkoutId);
  }, [location]);

  const copySignupLink = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl);
    setAlertMessage("Signup link copied to clipboard! You can now share it.");
  };

  // Redirect to /dashboard if the user is authenticated
  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <PageLayout>
      <div className="post-container">
        <h1 className="post-heading">
          Thank You for Subscribing! Your payment was successful.
        </h1>
        <h2 className="post-subheading">
          You can sign up for an account by clicking the Sign Up button below.
        </h2>

        {/* Inline Signup Button Replacement */}
        <button
          onClick={() =>
            (window.location.href = "https://account.repbot.ai/?signup=true")
          }
          className="post-button"
        >
          Sign Up
        </button>

        <div className="post-box">
          <p className="post-paragraph">
            If you&apos;re not signing up now, click the button below to copy
            your personalized sign-up link. You can email it to yourself or
            share it with a team member. If this link isn&apos;t used to sign
            up, you may need to contact support to connect the subscription to
            your account.
          </p>
          <button onClick={copySignupLink} className="post-button">
            Copy Signup Link
          </button>

          {alertMessage && <p className="post-alert-message">{alertMessage}</p>}
        </div>
      </div>
    </PageLayout>
  );
};

export default Post;
