// src/pages/gmb-integration.js
import React from "react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useFetchLocations from "../hooks/useFetchLocations";
import { useAuth0 } from "@auth0/auth0-react";
import Retool from "react-retool";
import useIframeRedirect from "../hooks/useIframeRedirect";
import { useUpdateViaIframe } from "../hooks/useUpdateViaIframe";

const GMBIntegrationPage = () => {
  useDocumentTitle("GMB Integration");

  const { user } = useAuth0();
  const { locations, authData, loading, error } = useFetchLocations();

  // Hook to redirect or perform actions via iframe
  useIframeRedirect("https://tryrepbot.retool.com");

  // Listen for iframe messages to update connection status
  useUpdateViaIframe("https://tryrepbot.retool.com", "gmb_connected");

  const retoolData = {
    userName: user.name,
    userEmail: user.email,
    locations: locations || [],
    authData: authData || {},
  };

  if (loading) return <p>Loading business location names...</p>;
  if (error) return <p>Error loading locations: {error.message}</p>;

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          GMB Integration
        </h1>

        {/* Retool Iframe */}
        <Retool
          url="https://tryrepbot.retool.com/p/gmb-integration"
          width="100%"
          height="800px"
          data={retoolData}
        />
      </div>
    </PageLayout>
  );
};

export default GMBIntegrationPage;
