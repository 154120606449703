/* eslint-disable prettier/prettier */
// src/components/BillingComponent.js

import React from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const BillingComponent = () => {
  return (
    <Box textAlign="center">
      <Stack
        spacing={3}
        alignItems="center"
        sx={{
          maxWidth: 700,
          margin: "0 auto",
        }}
      >
        <Box>
          <a
            href="https://billing.stripe.com/p/login/14kbJD24SeaJdlS288"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="stripe-payment.png"
              alt="Secure Billing with Stripe"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </a>
        </Box>
        <Typography variant="body1">
        Your payments are securely processed through Stripe, using industry-leading encryption. <br/>Click the button below to securely manage your billing in Stripe&apos;s portal.
        </Typography>
        <Button
          href="https://billing.stripe.com/p/login/14kbJD24SeaJdlS288"
          target="_blank"
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          fullWidth
          sx={{ fontSize: '20px' }}
        >
          VISIT STRIPE
        </Button>
      </Stack>
    </Box>
  );
};

export default BillingComponent;