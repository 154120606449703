/* eslint-disable prettier/prettier */
// src/hooks/useLocalStorage.js
import { useState } from "react";

const useLocalStorage = (key, initialValue) => {
  const storedValue = localStorage.getItem(key) ?? initialValue; // Ensure the value is directly fetched as a string
  const [value, setValue] = useState(storedValue);

  const setStoredValue = (newValue) => {
    setValue(newValue);
    localStorage.setItem(key, newValue); // Store value as plain string
  };

  return [value, setStoredValue];
};

export default useLocalStorage;
