/* eslint-disable prettier/prettier */
// src/pages/reviews.js
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";
import Modal from "../components/modal";
import GoogleSSO from "../components/GoogleSSO";
import useIframeModal from "../hooks/useIframeModal";
import useIframeRedirect from "../hooks/useIframeRedirect";

const ReviewsPage = () => {
  useDocumentTitle("Reviews");

  const { user } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paid, setPaid] = useState(false);
  const [tone_saved, settone_saved] = useState(false);

  useIframeModal("https://tryrepbot.retool.com", () => setIsModalOpen(true));
  useIframeRedirect("https://tryrepbot.retool.com");

  useEffect(() => {
    setPaid(localStorage.getItem("paid") === "true");
    settone_saved(localStorage.getItem("tone_saved") === "true");
  }, []);

  const iframeUrl = !paid
    ? "https://tryrepbot.retool.com/p/reviews-free"
    : tone_saved
    ? "https://tryrepbot.retool.com/p/reviews-gmb-tone-yes"
    : null;

  const closeModal = () => setIsModalOpen(false);

  const retoolData = {
    userEmail: user?.email || "", // Updated to match the format in dashboard-page.js
  };

  return (
    <PageLayout>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <GoogleSSO />
        </Modal>
      )}
      {iframeUrl ? (
        <Retool
          url={iframeUrl}
          width="100%"
          height="1000px"
          data={retoolData}
        />
      ) : (
        <div
          style={{ textAlign: "center", marginTop: "50px", minHeight: "70vh" }}
        >
          <h1>Set Your Brand Rules </h1>
          <h2>
            To ensure your responses reflect your unique brand voice, please
            review and save your <a href="/brand-rules">Brand Rules</a> before
            we generate personalized replies.
          </h2>
        </div>
      )}
    </PageLayout>
  );
};

export default ReviewsPage;
