/* eslint-disable prettier/prettier */
// src/hooks/useIframeRedirect.js

import { useEffect } from "react";

/**
 * Custom hook to listen for redirect messages from an iframe and perform redirection.
 *
 * @param {string} expectedOrigin - The origin to validate incoming messages.
 */
const useIframeRedirect = (expectedOrigin) => {
  useEffect(() => {
    /**
     * Handler for message events.
     *
     * @param {MessageEvent} event - The message event.
     */
    const handleMessage = (event) => {
      // Validate the message origin
      if (event.origin !== expectedOrigin) {
        console.warn(`Ignored message from unexpected origin: ${event.origin}`);
        return;
      }

      // Ensure the message contains a URL
      const { url } = event.data;
      if (typeof url === "string" && isValidUrl(url)) {
        // Perform the redirection
        window.location.href = url;
      } else {
        console.warn("Received message without a valid URL:", event.data);
      }
    };

    // Add the event listener
    window.addEventListener("message", handleMessage);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [expectedOrigin]);
};

/**
 * Simple URL validation.
 *
 * @param {string} url - The URL to validate.
 * @returns {boolean} - True if valid, false otherwise.
 */
const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export default useIframeRedirect;
