/* eslint-disable prettier/prettier */
// src/components/buttons/login-button.js
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      prompt: "login",
      appState: {
        returnTo: "/dashboard",  // Change to "/dashboard" for logins
        loginType: "login",  // Mark this as a login event
      },
    });
  };

  return (
    <button className="button__login" onClick={handleLogin}>
      Log In
    </button>
  );
};
