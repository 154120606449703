/* eslint-disable prettier/prettier */
// src/hooks/useIframeModal.js

import { useEffect } from "react";

/**
 * Custom hook to listen for modal trigger messages from an iframe and execute a callback.
 *
 * @param {string} expectedOrigin - The origin to validate incoming messages.
 * @param {Function} onTrigger - The callback to execute when a modal trigger message is received.
 */
const useIframeModal = (expectedOrigin, onTrigger) => {
  useEffect(() => {
    /**
     * Handler for message events.
     *
     * @param {MessageEvent} event - The message event.
     */
    const handleMessage = (event) => {
      // Validate the message origin
      if (event.origin !== expectedOrigin) {
        console.warn(`Ignored message from unexpected origin: ${event.origin}`);
        return;
      }

      // Check if the message contains a trigger for the modal
      const { data } = event;
      if (data && data.triggerModal) {
        onTrigger(); // Execute the callback to open the modal
      }
    };

    // Add the event listener
    window.addEventListener("message", handleMessage);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [expectedOrigin, onTrigger]);
};

export default useIframeModal;
