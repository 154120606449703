/* eslint-disable prettier/prettier */
// src/services/fetchAccountData.js

import axios from "axios";

export const fetchAccountData = async (email) => {
  if (!email) throw new Error("Email is required to fetch account data");

  try {
    const response = await axios.get(`/api/get-account-data?email=${email}`);
    return response.data;
  } catch (error) {
    console.error("taskid: z99 - Error fetching account data:", error);
    throw error;
  }
};
