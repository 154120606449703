/* eslint-disable prettier/prettier */
// src/auth0-provider-with-navigate.js
import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri =
    process.env.REACT_APP_VERCEL_ENV === "production"
      ? `https://${window.location.hostname}/callback`
      : process.env.REACT_APP_AUTH0_CALLBACK_URL;

  const onRedirectCallback = () => {
    navigate("/dashboard"); // Always navigate to /dashboard
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
