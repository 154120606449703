/* eslint-disable prettier/prettier */
// src/pages/onboarding-page.js
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLayout } from "../components/page-layout";

export const OnboardingPage = () => {
  const { user } = useAuth0();

  if (!user) {
    return null; // Ensure the user is authenticated before rendering
  }

  return (
    <PageLayout hideSecondaryNav={true}>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Profile Page
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>
              You can use the <strong>ID Token</strong> to get user profile
              information.
            </span>
          </p>
          <div className="profile-grid">
            <div className="profile__header">
              <img
                src={user.picture}
                alt="Profile Avatar"
                className="profile__avatar"
              />
              <div className="profile__headline">
                <h2>{user.name}</h2>
                <p>{user.email}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
